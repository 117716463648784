<template>
  <div class="tag-input">
    <div v-for="(tag, index) in tags" :key="index" class="tag-input__tag">
      {{ tag }}
      <span @click="removeTag(index)">x</span>
    </div>
    <input
      type="text"
      class="tag-input__text"
      :placeholder="placeholder"
      @keydown="addTag"
      @keydown.delete="removeLastTag"
      @change="onChangeTag"
    />
  </div>
</template>

<script>
export default {
  props: ["tags", "placeholder", "item"],
  methods: {
    addTag(event) {
      if (event.code === "Comma" || event.code === "Enter") {
        event.preventDefault();
        let val = event.target.value.trim();

        if (val.length > 0) {
          this.tags.push(val);
          event.target.value = "";
          console.log(this.item);
        }
      }
    },
    onChangeTag(event) {
      let val = event.target.value.trim();

      if (val.length > 0) {
        this.tags.push(val);
        event.target.value = "";
      }
    },
    removeTag(index) {
      this.tags.splice(index, 1);
    },
    removeLastTag(event) {
      if (event.target.value.length === 0) {
        this.removeTag(this.tags.length - 1);
      }
    },
  },
};
</script>

<style>
</style>